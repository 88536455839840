import { createSlice, createSelector } from '@reduxjs/toolkit';

const initialState = {
  onboardingProgress: null,
  isSandboxMode: false,
  currency: 'USD',
};

const slice = createSlice({
  name: 'orgSettings',
  initialState,
  reducers: {
    setOnboardingProgress: (state, { payload }) => {
      state.onboardingProgress = payload;
    },
    setSandboxMode: (state, { payload }) => {
      state.isSandboxMode = payload;
    },
    setOrgCurrency: (state, { payload }) => {
      state.currency = payload;
    },
  },
});

// ACTIONS
export const { setOnboardingProgress, setSandboxMode, setOrgCurrency } = slice.actions;

// SELECTORS
const selectOrgSettingsData = (state) => {
  return state.orgSettings;
};

export const selectOnboardingProgress = createSelector(selectOrgSettingsData, (data) => data.onboardingProgress);
export const selectSandboxMode = createSelector(selectOrgSettingsData, (data) => data.isSandboxMode);
export const selectOrgCurrency = createSelector(selectOrgSettingsData, (data) => data.currency);

export default slice.reducer;
